'use client';

import styled from 'styled-components';

import notFound from '@/public/images/error.jpeg';

interface ErrorStateProps {
  error: Error;
  reset: () => void;
}

const Error: React.FC<ErrorStateProps> = () => {
  return <Wrapper></Wrapper>;
};

const Wrapper = styled.div`
  background-image: url(${notFound.src});
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  background-size: auto;
`;

export default Error;
